@import "variables";
//Bootstrap Required
@import "../../Contrib/Bootstrap/scss/functions";
@import "../../Contrib/Bootstrap/scss/variables";
@import "../../Contrib/Bootstrap/scss/mixins";
$custom-select-indicator: str-replace(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M27.7 47L3.9 23.3.1 27.1l23.8 23.7 2.4 2.4 23.8 23.7 3.8-3.8-23.8-23.7z' fill='#{$custom-select-indicator-color}'/%3E%3Cpath d='M76.2 50.7l23.7-23.8-3.8-3.8-23.7 23.8-2.4 2.4-23.7 23.8 3.8 3.8 23.7-23.8z' fill='#{$custom-select-indicator-color}'/%3E%3C/svg%3E"), "#", "%23");
$custom-select-background: $custom-select-indicator no-repeat right $custom-select-padding-x center / $custom-select-bg-size;

//Bootstrap Optional
@import "../../Contrib/Bootstrap/scss/forms";
@import "../../Contrib/Bootstrap/scss/custom-forms";

.frame .tx-powermail {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;

    @include media-breakpoint-up(lg) {
        margin-top: 7.875rem;
        margin-bottom: 7.875rem;
    }


    .c-type-text {
        a {
            color: $orange;
        }
    }
}

.form-control,
.custom-select {
    &:focus {
        box-shadow: none;
    }
}

.form-control {
    &.form-file-control {
        height: auto;
    }

    &.error,
    &.powermail_field_error {
        margin-bottom: 2rem;
        border-color: $orange;
        padding-right: 1rem;
        background-image: $form-feedback-icon-invalid;
        background-repeat: no-repeat;
        background-position: center right;
        background-size: $input-height-inner-half $input-height-inner-half;

        &:focus {
            border-bottom-color: $orange;
        }

        &::placeholder {
            color: $orange;
        }
    }
}

.custom-select {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;

    &:focus {
        color: $gray-main;
    }

    &.error {
        border-color: $orange;
    }

    &:hover {
        cursor: pointer;
    }

    .current {
        display: block;
        outline: none;
        width: 100%;

        &:hover {
            cursor: pointer;
        }
    }

    .list {
        display: none;
        position: absolute;
        left: 0;
        bottom: -1px;
        transform: translateY(100%);
        overflow-y: auto;
        outline: none;
        box-shadow: 0 3px 5px -2px $gray-600;
        z-index: 999;
        list-style: none;
        padding: 0;
        margin: 0;
        background: $white;
        max-height: 20.75rem;
        width: 100%;

        li {
            padding: 0.35rem 0.5rem;
            transition: all 0.3s;

            &:hover {
                cursor: pointer;
                color: $white;
                background: $primary;
            }
        }
    }

    &.open {
        ~ .icon-arrow {
            transform: translateY(-50%) scale(-1);
        }

        .list {
            display: block;
        }
    }
}

.custom-control-label {
    &::before {
        top: 0.375rem;
    }

    &::after {
        top: 0.375rem;
    }

    @include media-breakpoint-up(md) {
        &::before {
            top: 0.4375rem;
        }

        &::after {
            top: 0.4375rem;
        }
    }
}

.form-control-text-wrapper {
    position: relative;

    .form-border {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: $orange;
        transition: width 0.15s ease-in-out;
    }

    .form-control:focus + .form-border {
        width: 8px;
    }
}

.checkbox-group,
.radio-group {
    > label {
        width: 100%;
    }
}

.file-info {
    line-height: 1.28;
}

.powermail-errors-list {
    position: absolute;
    margin: -1.8rem 0 0;
    padding: 0;
    list-style-type: none;
    list-style-image: none;
    font-size: 1rem;
    color: $orange;

    li {
        padding-left: 0;
    }
}
